import React from 'react';
import { Card, Row } from 'reactstrap';

export const Report = () => {
  const iframe_src = `https://preprodrainspot.com/sorry-cypress/output.html`;
  return (
    <Card className="jh-card">
      <div style={{height: "calc(100vh - 150px)"}}>
        <iframe
          title="Rainspot: Pre-Prod Cypress Report"
          id="woiframe"
          src={iframe_src}
          width="100%"
          height="100%"
          frameBorder="0"
        ></iframe>
      </div>
    </Card>
  );
};

export default Report;
