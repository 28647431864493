import { useAppDispatch, useAppSelector } from 'app/config/store';
import { formatDateWithTime } from 'app/shared/util/date-utils';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getEntities } from '../../device-payload/device-payload.reducer';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const DevicePayload = () => {
  const dispatch = useAppDispatch();
  const devicePayload = useAppSelector(state => state.devicePayload.entities);
  const loading = useAppSelector(state => state.devicePayload.loading);

  const { id } = useParams<'id'>();

  const getLatestEntities = () => {
    dispatch(
      getEntities({
        page: 0,
        size: 20,
        sort: `id,desc`,
        filterUrl: `deviceId.equals=${id}&`,
      }),
    );
  };
  useEffect(() => {
    getLatestEntities();
  }, []);

  return (
    <>
      <div className="payload-tab">
        <div className="d-flex justify-content-between">
          <div className="no-data">{devicePayload?.length === 0 ? <>No data found</>:<></>}</div>
          <Button color="info" className="me-2" onClick={getLatestEntities} style={{ pointerEvents: loading ? 'none' : 'all' }}>
            <FontAwesomeIcon icon="sync" spin={loading} /> Refresh List
          </Button>
        </div>
        {devicePayload?.map((payload, index) => (
          <>
            <div className="payload-time" data-testid={'time-' + index}>
              {formatDateWithTime(payload?.timeOfIngestion) + '-' + payload?.direction}
            </div>
            <div className="payload-content" data-testid={'content-' + index}>
              {payload?.value?.map(v => JSON.stringify(v))?.join(',')}
            </div>
            {index === devicePayload?.length - 1 ? <></> : <hr data-testid={'dash-' + index} />}
          </>
        ))}
      </div>
    </>
  );
};

export default DevicePayload;
