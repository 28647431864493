import React from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, CardImg } from 'reactstrap';
import './support.scss';
import { StepCard } from './stepCard';

const logoSrc = require('../../../content/images/21016946784d767683536fccc20b5f36.png');
const iphone_1 = require('../../../content/images/1.png');
const iphone_2 = require('../../../content/images/2.png');

const steps = [
  {
    number: 1,
    title: 'Navigate to the owner section',
    description: 'Go to the owner section of the app. This is accessible through a bottom bar menu.',
    imageSrc: iphone_1,
  },
  {
    number: 2,
    title: 'Locate the deletion option',
    description: 'Look for options Delete account.',
    imageSrc: iphone_1,
  },
  {
    number: 3,
    title: 'Submit the deletion request',
    description: 'Click on the Delete Account. Follow additional prompts to confirm your decision.',
    imageSrc: iphone_1,
  },
  {
    number: 4,
    title: 'Confirm deletion',
    description: 'Prompt requires you to confirm the deletion.',
    imageSrc: iphone_2,
  },
];

const DeleteAccount = () => {
  return (
    <div className="container">
      <div className="content">
        <img loading="lazy" src={logoSrc} alt="Company Logo" className="logo" />
        <div className="stepsContainer">
          {steps.map(step => (
            <StepCard key={step.number} number={step.number} title={step.title} description={step.description} imageSrc={step.imageSrc} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
