import React from 'react';

import './support.scss';

interface StepCardProps {
  number: number;
  title: string;
  description: string;
  imageSrc: string;
}

export const StepCard: React.FC<StepCardProps> = ({ number, title, description, imageSrc }) => {
  return (
    <div className="stepContainer">
      <div className="headerContainer">
        <div className="stepNumber">{number}</div>
        <div className="stepTitle">{title}</div>
      </div>
      <div className="stepDescription">{description}</div>
      <img loading="lazy" src={imageSrc} alt={`Step ${number} illustration - ${title}`} className="stepImage" />
    </div>
  );
};
