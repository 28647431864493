import React from 'react';
import { Translate } from 'react-jhipster';
import DeviceIcon from 'app/shared/icons/device-icons';
import HiveIcon from 'app/shared/icons/hive-icon';
import MapsIcon from 'app/shared/icons/maps-icon';
import NeighborhoodIcon from 'app/shared/icons/neighborhood-icon';
import OrganizationIcon from 'app/shared/icons/organization-icon';
import UsersIcon from 'app/shared/icons/user-icon';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { Nav } from 'reactstrap';
import { AUTHORITIES } from 'app/config/constants';
import MetricIcon from 'app/shared/icons/metric-icon';
import InventoryIcon from 'app/shared/icons/inventory-icon copy';
import MessageIcon from 'app/shared/icons/messages-icon';

export const EntitiesMenu = ({ authorities }) => {
  const roleBasedMenu = menu.filter(m => m.authorities.some(r => authorities.includes(r)));

  return (
    <>
      <Nav vertical>
        {roleBasedMenu.map((menuItem, index) => (
          <MenuItem id={'item-' + menuItem.id} key={index} icon={null} to={menuItem.to}>
            {menuItem.icon}
            <Translate contentKey={menuItem.title} />
          </MenuItem>
        ))}

        {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
      </Nav>
    </>
  );
};

export const InternalEntities = ({ authorities }) => {
  const roleBasedMenu = internalEntities.filter(m => m.authorities.some(r => authorities.includes(r)));
  return (
    <>
      {roleBasedMenu?.length > 0 && <div className="menu-section">INTERNAL</div>}
      <Nav vertical>
        {roleBasedMenu.map((menuItem, index) => (
          <MenuItem id={'item-' + menuItem.id} key={index} icon={null} to={menuItem.to}>
            {menuItem.icon}
            <Translate contentKey={menuItem.title} />
          </MenuItem>
        ))}

        {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
      </Nav>
    </>
  );
};

export const AdministrationMenu = ({ authorities }) => {
  const roleBasedMenu = administrationMenu.filter(m => m.authorities.some(r => authorities.includes(r)));
  return (
    <>
      {roleBasedMenu?.length > 0 && <div className="menu-section">ADMINISTRATIONS</div>}
      <Nav vertical>
        {roleBasedMenu.map((menuItem, index) => (
          <MenuItem id={'item-' + menuItem.id} key={index} icon={null} to={menuItem.to}>
            {menuItem.icon}
            <Translate contentKey={menuItem.title} />
          </MenuItem>
        ))}

        {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
      </Nav>
    </>
  );
};

const menu = [
  {
    id: 'device',
    to: '/device',
    title: 'global.menu.entities.device',
    icon: <DeviceIcon />,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.SUPERADMIN, AUTHORITIES.UTILITY_ADMIN, AUTHORITIES.UTILITY_USER, AUTHORITIES.DEVICE_OWNER],
  },
  {
    id: 'hive',
    to: '/hive',
    title: 'global.menu.entities.hive',
    icon: <HiveIcon />,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.SUPERADMIN, AUTHORITIES.UTILITY_ADMIN, AUTHORITIES.UTILITY_USER],
  },
  {
    id: 'maps',
    to: '/maps',
    title: 'global.menu.entities.maps',
    icon: <MapsIcon />,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.SUPERADMIN, AUTHORITIES.UTILITY_ADMIN],
  },
  {
    id: 'messages',
    to: '/messages',
    title: 'global.menu.entities.messages',
    icon: <MessageIcon />,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.SUPERADMIN, AUTHORITIES.UTILITY_ADMIN],
  },
];

const internalEntities = [
  {
    to: '/comment',
    id: 'comment',
    title: 'global.menu.entities.comment',
    icon: <>*</>,
    authorities: [AUTHORITIES.SUPERADMIN],
  },
  {
    id: 'city',
    to: '/city',
    title: 'global.menu.entities.city',
    icon: <>*</>,
    authorities: [AUTHORITIES.SUPERADMIN],
  },
  {
    id: 'device-payload',
    to: '/device-payload',
    title: 'global.menu.entities.devicePayload',
    icon: <>*</>,
    authorities: [AUTHORITIES.SUPERADMIN],
  },
  // {
  //   id: 'computed-metric',
  //   to: '/computed-metric',
  //   title: 'global.menu.entities.computedMetric',
  //   icon: <>*</>,
  //   authorities: [AUTHORITIES.SUPERADMIN],
  // },
  {
    id: 'report',
    to: '/cypressReport',
    title: 'global.menu.entities.cypressReport',
    icon: <>*</>,
    authorities: [AUTHORITIES.SUPERADMIN],
  },
];

const administrationMenu = [
  {
    id: 'inventory',
    to: '/inventory',
    title: 'global.menu.entities.inventory',
    icon: <InventoryIcon />,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.UTILITY_ADMIN, AUTHORITIES.SUPERADMIN],
  },
  {
    id: 'organization',
    to: '/organization',
    title: 'global.menu.entities.organization',
    icon: <OrganizationIcon />,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.UTILITY_ADMIN, AUTHORITIES.SUPERADMIN],
  },
  {
    id: 'user',
    to: '/application-user',
    title: 'global.menu.entities.applicationUser',
    icon: <UsersIcon />,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.UTILITY_ADMIN, AUTHORITIES.SUPERADMIN],
  },
  {
    id: 'neighborhood',
    to: '/neighborhood',
    title: 'global.menu.entities.neighborhood',
    icon: <NeighborhoodIcon />,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.SUPERADMIN],
  },
  {
    id: 'metric',
    to: '/device-reported-metric',
    title: 'global.menu.entities.metric',
    icon: <MetricIcon />,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.SUPERADMIN],
  },
];
