import { useAppDispatch } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import DeviceConfiguration from './configuration/device-tab-configuration';
import DeviceLocation from './device-location';
import DeviceHealth from './health/device-tab-health';
import DeviceMetrics from './metric/device-tab-metrics';
import DevicePayload from './payload/device-tab-payload';
import DeviceMessages from './messages/device-tab-messages';

export const DeviceUpdateTabs = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabFromUrl = queryParams.get('tab');
  const [activeTabId, setActiveTab] = useState('tab-configuration');

  useEffect(() => {
    if (tabFromUrl !== null) setActiveTab(tabFromUrl);
  }, [tabFromUrl]);

  return (
    <div className="tabs-layout">
      <Nav tabs>
        {/* <NavItem>
          <NavLink
            className={activeTabId === 'tab-metrics' ? 'active' : ''}
            onClick={function noRefCheck() {
              setActiveTab('tab-metrics');
            }}
          >
            Metrics
          </NavLink>
        </NavItem> */}
        <NavItem>
          <NavLink
            className={activeTabId === 'tab-configuration' ? 'active' : ''}
            onClick={function noRefCheck() {
              setActiveTab('tab-configuration');
            }}
          >
            Configuration
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={activeTabId === 'tab-health' ? 'active' : ''}
            onClick={function noRefCheck() {
              setActiveTab('tab-health');
            }}
          >
            Health
          </NavLink>
        </NavItem> */}
        <NavItem>
          <NavLink
            className={activeTabId === 'tab-payload' ? 'active' : ''}
            onClick={function noRefCheck() {
              setActiveTab('tab-payload');
            }}
          >
            Payload
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTabId === 'tab-owner-info' ? 'active' : ''}
            onClick={function noRefCheck() {
              setActiveTab('tab-owner-info');
            }}
          >
            Location
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTabId === 'tab-device-messages' ? 'active' : ''}
            onClick={function noRefCheck() {
              setActiveTab('tab-device-messages');
            }}
          >
            Messages
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTabId}>
        {/* <TabPane tabId="tab-metrics">
          <DeviceMetrics />
        </TabPane> */}
        <TabPane tabId="tab-configuration">
          <DeviceConfiguration />
        </TabPane>
        <TabPane tabId="tab-raw-data"></TabPane>
        <TabPane tabId="tab-logs"></TabPane>
        {/* <TabPane tabId="tab-health">
          <DeviceHealth />
        </TabPane> */}
        <TabPane tabId="tab-payload">
          <DevicePayload />
        </TabPane>
        <TabPane tabId="tab-owner-info">
          <DeviceLocation />
        </TabPane>
        <TabPane tabId="tab-device-messages">
          <DeviceMessages entity={'device'} />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default DeviceUpdateTabs;
