// src\main\webapp\app\entities\hive\utils.ts

export const hiveList = [
  {
    id: 7651,
    hopDepth: null,
    ipv4: 'jj',
    ipv6: 'jjjh',
    macAddress: 'jjjj8',
    uuid: '999ikk',
    serialNumber: 'jji9090',
    ssid: 'i',
    ssidPassword: 'm',
    timezone: 'm',
    state: 'ON',
    installedAddress: 'm',
    installedAddressZipcode: 'm',
    radioType: 'm',
    name: 'm',
    master: true,
    createdBy: 'j',
    modifiedBy: 'j',
    managed: false,
    testDevice: false,
    lat: 'j',
    longitude: 'j',
    nodeMesh: 'j',
    manager: null,
    subRows: [
      {
        id: 5301,
        hopDepth: 0,
        ipv4: '102',
        ipv6: '',
        macAddress: '101',
        uuid: '',
        serialNumber: '741741',
        ssid: '',
        ssidPassword: '',
        timezone: '',
        state: 'OFF',
        installedAddress: null,
        installedAddressZipcode: null,
        radioType: '',
        name: 'DeviceForDemo',
        dateCreated: '2024-02-27T19:00:00Z',
        dateUpdated: '2024-02-27T19:00:00Z',
        createdBy: '',
        modifiedBy: '',
        managed: false,
        testDevice: false,
        master: false,
        lat: '',
        longitude: '106',
        nodeMesh: '',
        user: {
          id: 3804,
          additionalField: null,
          address: null,
          city: null,
          state: null,
          zipcode: null,
          phone: null,
          createdDate: null,
          internalUser: null,
          organization: null,
        },
        org: {
          id: 2701,
          name: null,
          dateCreated: null,
          dateUpdated: null,
          createdBy: null,
          modifiedBy: null,
          status: null,
          streetAddress: null,
          city: null,
          state: null,
          zipCode: null,
          contactInfo: null,
        },
        city: {
          id: 230,
          name: null,
          region: null,
          state: null,
          county: null,
          organization: null,
        },
        neighborhood: {
          id: 2751,
          name: null,
          countId: null,
          stateId: null,
          regionId: null,
          city: null,
          createdBy: null,
          createdDate: null,
        },
        mesh: {
          id: 1351,
          name: null,
          subnet: null,
          numberOfNodes: null,
          uptime: null,
          jitter: null,
          latency: null,
          bandwidth: null,
          throughput: null,
          packetloss: null,
          numberOfPacketsReceived: null,
          numberOfPacketsTransmitted: null,
          packetRetransmittion: null,
          masterNodeSerialNumber: null,
          masterNodeMacAddress: null,
          masterNodeIpv4: null,
          masterNodeIpv6: null,
          neighborhood: null,
        },
        manager: {
          id: 4201,
          hopDepth: null,
          ipv4: null,
          ipv6: null,
          macAddress: null,
          uuid: null,
          serialNumber: null,
          ssid: null,
          ssidPassword: null,
          timezone: null,
          state: null,
          installedAddress: null,
          installedAddressZipcode: null,
          radioType: null,
          name: null,
          dateCreated: null,
          dateUpdated: null,
          createdBy: null,
          modifiedBy: null,
          managed: null,
          testDevice: null,
          master: null,
          lat: null,
          longitude: null,
          nodeMesh: null,
          user: null,
          org: null,
          city: null,
          neighborhood: null,
          mesh: null,
          manager: null,
        },
      },
      {
        id: 3503,
        hopDepth: 0,
        ipv4: '100',
        ipv6: '',
        macAddress: '105',
        uuid: '',
        serialNumber: '1561522552',
        ssid: '',
        ssidPassword: '',
        timezone: '',
        state: 'OFF',
        installedAddress: '',
        installedAddressZipcode: '',
        radioType: '',
        name: 'Device B',
        dateCreated: '2024-02-22T19:00:00Z',
        dateUpdated: '2024-02-22T19:00:00Z',
        createdBy: '',
        modifiedBy: '',
        managed: false,
        testDevice: false,
        master: false,
        lat: '',
        longitude: '100',
        nodeMesh: '',
        user: null,
        org: null,
        city: null,
        neighborhood: null,
        mesh: null,
        manager: {
          id: 4201,
          hopDepth: null,
          ipv4: null,
          ipv6: null,
          macAddress: null,
          uuid: null,
          serialNumber: null,
          ssid: null,
          ssidPassword: null,
          timezone: null,
          state: null,
          installedAddress: null,
          installedAddressZipcode: null,
          radioType: null,
          name: null,
          dateCreated: null,
          dateUpdated: null,
          createdBy: null,
          modifiedBy: null,
          managed: null,
          testDevice: null,
          master: null,
          lat: null,
          longitude: null,
          nodeMesh: null,
          user: null,
          org: null,
          city: null,
          neighborhood: null,
          mesh: null,
          manager: null,
        },
      },
      {
        id: 3502,
        hopDepth: 0,
        ipv4: '102',
        ipv6: '',
        macAddress: '103',
        uuid: '',
        serialNumber: '23728378',
        ssid: '',
        ssidPassword: '',
        timezone: '',
        state: 'OFF',
        installedAddress: '',
        installedAddressZipcode: '',
        radioType: '',
        name: 'test',
        dateCreated: '2024-02-22T19:00:00Z',
        dateUpdated: '2024-02-22T19:00:00Z',
        createdBy: '',
        modifiedBy: '',
        managed: false,
        testDevice: false,
        master: false,
        lat: '',
        longitude: '102',
        nodeMesh: '',
        user: null,
        org: null,
        city: null,
        neighborhood: null,
        mesh: null,
        manager: {
          id: 4201,
          hopDepth: null,
          ipv4: null,
          ipv6: null,
          macAddress: null,
          uuid: null,
          serialNumber: null,
          ssid: null,
          ssidPassword: null,
          timezone: null,
          state: null,
          installedAddress: null,
          installedAddressZipcode: null,
          radioType: null,
          name: null,
          dateCreated: null,
          dateUpdated: null,
          createdBy: null,
          modifiedBy: null,
          managed: null,
          testDevice: null,
          master: null,
          lat: null,
          longitude: null,
          nodeMesh: null,
          user: null,
          org: null,
          city: null,
          neighborhood: null,
          mesh: null,
          manager: null,
        },
      },
    ],
  },
  {
    id: 7651,
    hopDepth: null,
    ipv4: 'jj',
    ipv6: 'jjjh',
    macAddress: 'jjjj8',
    uuid: '999ikk',
    serialNumber: 'jji9090',
    ssid: 'i',
    ssidPassword: 'm',
    timezone: 'm',
    state: 'ON',
    installedAddress: 'm',
    installedAddressZipcode: 'm',
    radioType: 'm',
    name: 'm',

    createdBy: 'j',
    modifiedBy: 'j',
    managed: false,
    testDevice: false,
    master: false,
    lat: 'j',
    longitude: 'j',
    nodeMesh: 'j',
    manager: null,
    subRows: [
      { name: 'D7', id: 1, serialNumber: '130' },
      { name: 'D8', id: 1, serialNumber: '133' },
    ],
  },
  {
    id: 7651,
    hopDepth: null,
    ipv4: 'jj',
    ipv6: 'jjjh',
    macAddress: 'jjjj8',
    uuid: '999ikk',
    serialNumber: 'jji9090',
    ssid: 'i',
    ssidPassword: 'm',
    timezone: 'm',
    state: 'ON',
    installedAddress: 'm',
    installedAddressZipcode: 'm',
    radioType: 'm',
    name: 'm',

    createdBy: 'j',
    modifiedBy: 'j',
    managed: false,
    testDevice: false,
    master: false,
    lat: 'j',
    longitude: 'j',
    nodeMesh: 'j',
    manager: null,
  },
];

export const mockData = [
  {
    id: 4201,
    hopDepth: 0,
    ipv4: '100',
    ipv6: '',
    macAddress: '107',
    uuid: '',
    serialNumber: '114477',
    ssid: '',
    ssidPassword: '',
    timezone: '',
    state: 'ON',
    installedAddress: 'Devtek Inc',
    installedAddressZipcode: '1226',
    radioType: '',
    meshName: 'Parent Device',
    name: 'Parent Device',
    dateCreated: '2024-02-27T19:00:00Z',
    dateUpdated: '2024-02-27T19:00:00Z',
    createdBy: '',
    modifiedBy: '',
    managed: false,
    testDevice: false,
    master: true,
    lat: '',
    longitude: '107',
    nodeMesh: '',
    nodesCount: 3,
    nodePosition: 0,
    deviceLocation: {
      deviceAddress: 'Devtek Inc'
    },
    user: {
      id: 3804,
      additionalField: null,
      address: null,
      city: null,
      state: null,
      zipcode: null,
      phone: null,
      createdDate: null,
      internalUser: null,
      organization: null,
    },
    org: {
      id: 2701,
      name: null,
      dateCreated: null,
      dateUpdated: null,
      createdBy: null,
      modifiedBy: null,
      status: null,
      streetAddress: null,
      city: null,
      state: null,
      zipCode: null,
      contactInfo: null,
    },
    city: null,
    neighborhood: {
      id: 2751,
      name: 'Devtek Neighborhood',
      countId: null,
      stateId: null,
      regionId: null,
      city: null,
      createdBy: null,
      createdDate: null,
    },
    mesh: null,
    manager: null,
    subRows: [
      {
        id: 5301,
        hopDepth: 0,
        ipv4: '102',
        ipv6: '',
        macAddress: '101',
        uuid: '',
        serialNumber: '741741',
        ssid: '',
        ssidPassword: '',
        timezone: '',
        state: 'OFF',
        installedAddress: 'Devtek Inc',
        installedAddressZipcode: null,
        radioType: '',
        name: 'Child 1',
        dateCreated: '2024-02-27T19:00:00Z',
        dateUpdated: '2024-02-27T19:00:00Z',
        createdBy: '',
        modifiedBy: '',
        managed: false,
        testDevice: false,
        master: false,
        lat: '',
        longitude: '106',
        nodeMesh: '',
        deviceLocation: {
          deviceAddress: 'Devtek Inc'
        },
        user: {
          id: 3804,
          additionalField: null,
          address: null,
          city: null,
          state: null,
          zipcode: null,
          phone: null,
          createdDate: null,
          internalUser: null,
          organization: null,
        },
        org: {
          id: 2701,
          name: null,
          dateCreated: null,
          dateUpdated: null,
          createdBy: null,
          modifiedBy: null,
          status: null,
          streetAddress: null,
          city: null,
          state: null,
          zipCode: null,
          contactInfo: null,
        },
        city: {
          id: 230,
          name: null,
          region: null,
          state: null,
          county: null,
          organization: null,
        },
        neighborhood: {
          id: 2751,
          name: 'Devtek Neighborhood',
          countId: null,
          stateId: null,
          regionId: null,
          city: null,
          createdBy: null,
          createdDate: null,
        },
        mesh: {
          id: 1351,
          name: null,
          subnet: null,
          numberOfNodes: null,
          uptime: null,
          jitter: null,
          latency: null,
          bandwidth: null,
          throughput: null,
          packetloss: null,
          numberOfPacketsReceived: null,
          numberOfPacketsTransmitted: null,
          packetRetransmittion: null,
          masterNodeSerialNumber: null,
          masterNodeMacAddress: null,
          masterNodeIpv4: null,
          masterNodeIpv6: null,
          neighborhood: null,
        },
        manager: {
          id: 4201,
          hopDepth: null,
          ipv4: null,
          ipv6: null,
          macAddress: null,
          uuid: null,
          serialNumber: null,
          ssid: null,
          ssidPassword: null,
          timezone: null,
          state: null,
          installedAddress: null,
          installedAddressZipcode: null,
          radioType: null,
          name: null,
          dateCreated: null,
          dateUpdated: null,
          createdBy: null,
          modifiedBy: null,
          managed: null,
          testDevice: null,
          master: null,
          lat: null,
          longitude: null,
          nodeMesh: null,
          user: null,
          org: null,
          city: null,
          neighborhood: null,
          mesh: null,
          manager: null,
        },
      },
      {
        id: 3503,
        hopDepth: 0,
        ipv4: '100',
        ipv6: '',
        macAddress: '105',
        uuid: '',
        serialNumber: '742742',
        ssid: '',
        ssidPassword: '',
        timezone: '',
        state: 'OFF',
        installedAddress: 'Devtek Inc',
        installedAddressZipcode: '',
        radioType: '',
        name: 'Child 2',
        dateCreated: '2024-02-22T19:00:00Z',
        dateUpdated: '2024-02-22T19:00:00Z',
        createdBy: '',
        modifiedBy: '',
        managed: false,
        testDevice: false,
        master: false,
        lat: '',
        longitude: '100',
        nodeMesh: '',
        user: null,
        deviceLocation: {
          deviceAddress: 'Devtek Inc'
        },
        org: {
          id: 2701,
          name: null,
          dateCreated: null,
          dateUpdated: null,
          createdBy: null,
          modifiedBy: null,
          status: null,
          streetAddress: null,
          city: null,
          state: null,
          zipCode: null,
          contactInfo: null,
        },
        city: null,
        neighborhood: {
          id: 2751,
          name: 'Devtek Neighborhood',
          countId: null,
          stateId: null,
          regionId: null,
          city: null,
          createdBy: null,
          createdDate: null,
        },
        mesh: null,
        manager: {
          id: 4201,
          hopDepth: null,
          ipv4: null,
          ipv6: null,
          macAddress: null,
          uuid: null,
          serialNumber: null,
          ssid: null,
          ssidPassword: null,
          timezone: null,
          state: null,
          installedAddress: null,
          installedAddressZipcode: null,
          radioType: null,
          name: null,
          dateCreated: null,
          dateUpdated: null,
          createdBy: null,
          modifiedBy: null,
          managed: null,
          testDevice: null,
          master: null,
          lat: null,
          longitude: null,
          nodeMesh: null,
          user: null,
          org: null,
          city: null,
          neighborhood: null,
          mesh: null,
          manager: null,
        },
      },
      {
        id: 3502,
        hopDepth: 0,
        ipv4: '102',
        ipv6: '',
        macAddress: '103',
        uuid: '',
        serialNumber: '743743',
        ssid: '',
        ssidPassword: '',
        timezone: '',
        state: 'OFF',
        installedAddress: 'Devtek Inc',
        installedAddressZipcode: '',
        radioType: '',
        name: 'Child 3',
        dateCreated: '2024-02-22T19:00:00Z',
        dateUpdated: '2024-02-22T19:00:00Z',
        createdBy: '',
        modifiedBy: '',
        managed: false,
        testDevice: false,
        master: false,
        lat: '',
        longitude: '102',
        nodeMesh: '',
        user: null,
        deviceLocation: {
          deviceAddress: 'Devtek Inc'
        },
        org: {
          id: 2701,
          name: null,
          dateCreated: null,
          dateUpdated: null,
          createdBy: null,
          modifiedBy: null,
          status: null,
          streetAddress: null,
          city: null,
          state: null,
          zipCode: null,
          contactInfo: null,
        },
        city: null,
        neighborhood: {
          id: 2751,
          name: 'Devtek Neighborhood',
          countId: null,
          stateId: null,
          regionId: null,
          city: null,
          createdBy: null,
          createdDate: null,
        },
        mesh: null,
        manager: {
          id: 4201,
          hopDepth: null,
          ipv4: null,
          ipv6: null,
          macAddress: null,
          uuid: null,
          serialNumber: null,
          ssid: null,
          ssidPassword: null,
          timezone: null,
          state: null,
          installedAddress: null,
          installedAddressZipcode: null,
          radioType: null,
          name: null,
          dateCreated: null,
          dateUpdated: null,
          createdBy: null,
          modifiedBy: null,
          managed: null,
          testDevice: null,
          master: null,
          lat: null,
          longitude: null,
          nodeMesh: null,
          user: null,
          org: null,
          city: null,
          neighborhood: null,
          mesh: null,
          manager: null,
        },
      },
    ],
  },
];

export const HIVE_TABLE_FILTER_QUERY = {
  name: 'name.contains',
  installedAddress: 'installedAddress.contains',
  nodesCount: 'nodesCount.equals',
  serialNumber: 'serialNumber.equals',
  rxTraffic: 'rxTraffic.equals',
  state: 'state.contains',
  meshName: 'meshName.contains',
  nodePosition: 'nodePosition.equals',
};
