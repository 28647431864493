import 'app/config/dayjs';
import 'react-toastify/dist/ReactToastify.css';
import './app.scss';

import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import Footer from 'app/shared/layout/footer/footer';
import Header from 'app/shared/layout/header/header';
import { getProfile } from 'app/shared/reducers/application-profile';
import { getSession } from 'app/shared/reducers/authentication';
import L from 'leaflet';
import { Outlet } from 'react-router-dom';
import { dataroutes } from './routes';
import { SideBar } from './shared/layout/sidebar/sidebar';

/* eslint-disable @typescript-eslint/no-var-requires */
const markerIcon = require('leaflet/dist/images/marker-icon.png');
const markerShadow = require('leaflet/dist/images/marker-shadow.png');
/* eslint-enable @typescript-eslint/no-var-requires */

const DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

export const AppLayout = () => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  return (
    <div className="app-container">
      <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
      {isAuthenticated ? <AuthenticatedLayout /> : <UnauthenticatedLayout />}
    </div>
  );
};
const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    children: dataroutes,
  },
]);

const AuthenticatedLayout = () => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const isAdmin = useAppSelector(state =>
    hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN, AUTHORITIES.SUPERADMIN]),
  );
  const authorities = useAppSelector(state => state.authentication.account.authorities);
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);

  return (
    <>
      <ErrorBoundary>
        <Header
          isAuthenticated={isAuthenticated}
          isAdmin={isAdmin}
          currentLocale={currentLocale}
          ribbonEnv={ribbonEnv}
          isInProduction={isInProduction}
          isOpenAPIEnabled={isOpenAPIEnabled}
        />
      </ErrorBoundary>
      <div>
        <div className="side-bar">
          <SideBar authorities={authorities} />
        </div>
        <div className="content-area">
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </div>
      </div>
    </>
  );
};

const UnauthenticatedLayout = () => {
  return (
    <div className="logout" id="app-view-container">
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
      <Footer />
    </div>
  );
};

export const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSession());
    dispatch(getProfile());
  }, []);

  return <RouterProvider router={router} />;
};

export default App;
